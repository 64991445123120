// extracted by mini-css-extract-plugin
export var tileContent = "x_tn d_v d_G d_Y";
export var teamTextLeft = "x_tp d_dt";
export var teamTextCenter = "x_tq d_dv";
export var teamTextRight = "x_tr d_dw";
export var alignLeft = "x_pL d_v d_by d_fn d_bF d_dt";
export var alignCenter = "x_bN d_v d_by d_fp d_bC d_dv";
export var alignRight = "x_pM d_v d_by d_fq d_bG d_dw";
export var teamContainer = "x_ts d_dV";
export var teamContainerFull = "x_tt d_dS";
export var teamRowWrapper = "x_tv d_cb";
export var teamTileWrapper = "x_j3 d_j3 d_Y d_ct";
export var teamTileSquareWrapper = "x_tw d_j4 d_Y d_ct";
export var teamTileRoundWrapper = "x_j4 d_j4 d_Y d_ct";
export var teamTileNoGuttersWrapper = "x_j5 d_j5 d_Y";
export var teamHoverNoGutters = "x_j6 d_j6 d_Z d_v d_G d_by d_bC d_bN d_bl";
export var teamImageText = "x_kc d_kc d_bk d_v d_bx";
export var teamInfoWrapperSquare = "x_tx d_j7";
export var teamInfoWrapperRound = "x_j7 d_j7";
export var teamInfoWrapper = "x_ty d_j8 d_Z";
export var teamInfoWrapperNoGutters = "x_j9 d_j9 d_v d_G d_bC d_bL d_bH d_c6";
export var teamImgWrapper = "x_jZ d_jZ";
export var teamImgWrapperAlt = "x_j0 d_j0";
export var teamImgWrapperNoGutters = "x_kb d_kb";
export var teamHeader = "x_tz d_cv";
export var teamHeaderAlt = "x_tB d_cv";
export var teamHeaderNoGutters = "x_tC d_cv d_cC";