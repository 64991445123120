// extracted by mini-css-extract-plugin
export var alignLeft = "l_pL d_fn d_bF d_dt";
export var alignCenter = "l_bN d_fp d_bC d_dv";
export var alignRight = "l_pM d_fq d_bG d_dw";
export var FAQMainHeader = "l_kv d_kv d_v d_bx";
export var FAQComponentHeader = "l_kB d_kB d_bw";
export var FAQComponentParagraph = "l_kw d_kw d_cC";
export var FAQComponentsWrapper = "l_ks d_ks d_cm d_cK d_cQ d_c1 d_cW d_dz";
export var FAQComponentsWrapperClean = "l_kt d_kt d_cm d_cK d_cQ d_cZ d_dz";
export var accordion__body = "l_kC d_kC d_bx";
export var accordion__bodyHidden = "l_kF d_kF d_bv d_dk";
export var FAQDivider = "l_kr d_kr";
export var accordionWrapper = "l_pN d_cf";
export var accordion__item = "l_pP d_cK";
export var accordion_arrow = "l_kJ d_kJ";
export var accordion_arrow_open = "l_kL d_kL";
export var accordion_arrow_2 = "l_kN d_kN";
export var accordion_arrow_2_open = "l_kQ d_kQ";