// extracted by mini-css-extract-plugin
export var alignDiscLeft = "m_pQ d_fn d_bF d_dt";
export var alignLeft = "m_pL d_fn d_bF d_dt";
export var alignDiscCenter = "m_pR d_fp d_bC d_dv";
export var alignCenter = "m_bN d_fp d_bC d_dv";
export var alignDiscRight = "m_pS d_fq d_bG d_dw";
export var alignRight = "m_pM d_fq d_bG d_dw";
export var footerContainer = "m_pT d_dV d_bV";
export var footerContainerFull = "m_pV d_dS d_bV";
export var footerHeader = "m_kf d_kf";
export var footerTextWrapper = "m_pW d_v";
export var footerDisclaimerWrapper = "m_km d_km d_cj";
export var badgeWrapper = "m_pX d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "m_pY undefined";
export var footerDisclaimerLeft = "m_pZ undefined";
export var verticalTop = "m_p0 d_by d_bH d_bM d_bK";
export var firstWide = "m_p1";
export var disclaimer = "m_p2 d_by d_bH";
export var socialDisclaimer = "m_p3";
export var left = "m_p4";
export var wide = "m_p5 d_cx";
export var right = "m_p6 d_bJ";
export var line = "m_fj d_fk d_ct";
export var badgeDisclaimer = "m_p7 d_bB d_bN d_bH";
export var badgeContainer = "m_p8 d_by d_bG d_bN";
export var badge = "m_p9";
export var padding = "m_qb d_c6";
export var end = "m_qc d_bG";
export var linkWrapper = "m_qd d_dz";
export var link = "m_mC d_dz";
export var colWrapper = "m_qf d_cw";
export var consent = "m_g d_g d_bB d_bN";
export var media = "m_qg d_bw d_dx";
export var itemRight = "m_qh";
export var itemLeft = "m_qj";
export var itemCenter = "m_qk";
export var exceptionWeight = "m_ql q_rk";