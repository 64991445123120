// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_s2 d_gv d_cr";
export var heroHeaderCenter = "t_gw d_gw d_cr d_dv";
export var heroHeaderRight = "t_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "t_s3 d_gr d_cv";
export var heroParagraphCenter = "t_gs d_gs d_cv d_dv";
export var heroParagraphRight = "t_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "t_s4 d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "t_s5 d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "t_s6 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "t_s7 d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "t_s8 d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "t_rM q_rM";
export var heroExceptionNormal = "t_rN q_rN";
export var heroExceptionLarge = "t_rP q_rP";
export var Title1Small = "t_rn q_rn q_qT q_qV";
export var Title1Normal = "t_rp q_rp q_qT q_qW";
export var Title1Large = "t_rq q_rq q_qT q_qX";
export var BodySmall = "t_rC q_rC q_qT q_rb";
export var BodyNormal = "t_rD q_rD q_qT q_rc";
export var BodyLarge = "t_rF q_rF q_qT q_rd";